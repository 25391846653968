import * as React from "react"
import { navigate } from "@reach/router";

const IndexPage = () => {
  React.useEffect(() => {
    navigate("/");
  }, []);

  return null;
}

export default IndexPage
